// ---------------------------------
// article-slider
// ---------------------------------
// .article-slider {
// &--type_spots{}
// I can not add class for span, so I add design to span directry.
// &__pagination span {}
// }

.article-slider__slide {
  height: auto;
}

.article-slider__pagination {
  position: static;
}
