// Sec-access-mv
// ------------------------------
//.Sec-access-mv {}

.access-mv {
  position: relative;

  &__img {
    height: auto;
    width: 100%;
    object-fit: cover;

    @include media-breakpoint-down(md) {
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
}

.access-mv-txts {
  position: absolute;
  text-align: center;
  width: 100%;
  padding: 0 3rem;
  text-shadow: 2px 2px 6px colors("black");
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);

  @include media-breakpoint-down(md) {
    position: static;
    padding: 2rem 1.5rem;
    z-index: 1;
    top: 0;
    transform: translateY(0);
  }

  &__lead {
    color: colors("white");
    margin-bottom: 0;
    font-weight: bold;
    line-height: 1.8;
    text-shadow: 2px 2px 6px colors("black");

    @include media-breakpoint-down(md) {
      font-size: 1.4rem;
      text-align: left;
    }
  }
}

// Sec-access-method
// ------------------------------
//.Sec-access-method {}

.access-methods {
  &__ttl {
    text-align: center;
  }
}

.access-method {
  position: relative;

  @include media-breakpoint-down(md) {
    margin: 1rem 0;
  }
}

.access-method-link {
  display: flex;
}

.access-method-imgs {
  width: 100%;

  &__img {
    width: 100%;
    object-fit: cover;

    &:hover {
      opacity: 0.8;
      transition: 0.8s;
    }

    @include media-breakpoint-down(md) {
      height: 30vw;
      width: 100%;
    }
  }
}

.access-method-ttls {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);

  &__ttl {
    margin-bottom: 0;
  }
}

// Sec-access-detail
// ------------------------------
//.Sec-access-detail {}

.access-detail {
  &__ttl {
    text-align: center;

    @include media-breakpoint-up(lg) {
      margin-bottom: 3rem;
    }
  }
}

.access-detail-imgs {
  &__img {
    height: auto;
    width: 100%;
  }
}

.access-detail-txts {
  &__lead {
    line-height: 1.8;
    margin-bottom: 2rem;
  }

  @include media-breakpoint-down(md) {
    margin-top: 2rem;
  }
}

.access-detail-route {
  background-color: colors("blue-100");
  background-clip: content-box;
  margin-top: 3rem;
}

.access-detail-route-place {
  &__ttl {
    margin-bottom: 0;
  }

  &__link {
    font-size: 1.4rem;
  }
}

.access-detail-route-period {
  margin: 0.5rem 0;
}

.access-detail-route-period-bars {
  height: 100%;
  float: left;
  padding-left: 40px;
  padding-right: 20px;

  &__bar {
    border-right: dashed 5px colors("blue-600");
    height: 100%;
  }
}

.access-detail-route-period-times {
  &__lead {
    color: colors("blue");
    font-weight: bold;
    margin-bottom: 0;
    padding-left: 0.4rem;
  }

  &__time {
    background-color: colors("white");
    color: colors("red-700");
    font-weight: bold;
    display: inline-block;
    padding: 0.4rem 0.4rem 0;
  }
}

.access-detail-route-total-times {
  &__time {
    background-color: colors("white");
    color: colors("red-700");
    font-weight: bold;
    display: inline-block;
    padding: 0.4rem 0.4rem 0;
    margin-left: 4rem;
  }
}

// Sec-access-roadmap
// ------------------------------
//.Sec-access-roadmap {}

.access-roadmap {
  &__ttl {
    text-align: center;
  }
}

.access-roadmap-imgs {
  &__img {
    height: auto;
    width: 100%;
  }
}
