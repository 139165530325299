// ---------------------------------
// howto-modal-main-sec
// ---------------------------------
.howto-modal {
  &__content {
    border-radius: 20px;
  }

  &__header {
    border-radius: 20px 20px 0 0;
    background-color: colors("black-900");
  }

  &__ttl {
    font-size: 2.6rem;
    color: colors("white");
    display: block;
    width: 100%;
    text-align: center;

    @include media-breakpoint-down(sm) {
      font-size: 2.2rem;
    }
  }

  &__close-icon {
    color: colors("white");
    opacity: 1;
    transition: color 0.5s ease;
  }
}

.howto-modal-pager {
  &__icon {
    $_x-offset: 10px;

    @mixin howto-modal-pager-cmn() {
      position: absolute;
      top: 200px;
      margin: auto;
      padding: 8px 5px 5px 5px;
      border: 1px solid colors("gray-700");
      display: inline-block;
      height: 55px;
      font-size: 3.6rem;
      color: colors("white");
      cursor: pointer;
      z-index: 999;
      background-color: colors("alpha-black-80");
      box-shadow: 2px 2px 2px colors("alpha-black-40");
      transition: color 0.3s ease;

      &:hover {
        color: colors("pink");
      }

      &.disabled {
        color: colors("gray-600");
        background-color: rgba(colors("gray"), 0.8);
        cursor: default;
      }
    }

    &--type_left {
      @include howto-modal-pager-cmn;

      left: $_x-offset;
    }

    &--type_right {
      @include howto-modal-pager-cmn;

      right: $_x-offset;
    }
  }
}

.howto-cnt {
  &__ttl {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1.5rem;

    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
    }

    &-num {
      display: inline-block;
      background-color: colors("pink");
      color: colors("white");
      font-size: 1.1rem;
      font-weight: bold;
      margin-right: 5px;
      padding: 8px;
    }
  }

  // &__imgs { }

  &__img {
    width: 100%;
  }

  // &__detail {
  //   &-items {}

  //   &-item {}
  // }
}
