// ========================================
// mv-with-cards
//
// [Structure]
// .mv-with-cards
//   .mv-with-cards-mv-part
//   .mv-with-cards-cards-part
//
// ========================================

// ---------------------------------
// Globals
// ---------------------------------
$_mv-with-cards-mv-height: 50rem;
$_mv-with-cards-mv-md-height: 30rem;


// ---------------------------------
// .mv-with-cards (Layout Wrapper Block)
// ---------------------------------
// .mv-with-cards {}


// ---------------------------------
//  .mv-with-cards-mv-part
// ---------------------------------
.mv-with-cards-mv-part {
  width: 100%;
  height: $_mv-with-cards-mv-height;
  position: relative;
  display: inline-block;

  @include media-breakpoint-down(md) {
    height: $_mv-with-cards-mv-md-height;
    display: flex;
    align-items: center;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__txts {
    width: 100%;
    text-align: center;
    position: absolute;
    padding: 0 3rem;
    top: 30%;
    left: 0;
    right: 0;

    @include media-breakpoint-down(md) {
      top: 50%;
      transform: translateY(-50%);
    }

    @include media-breakpoint-down(sm) {
      padding: 0 1.5rem;
    }
  }

  &__lead {
    color: colors("white");
    text-shadow: 2px 2px 6px colors("black");
    font-weight: bold;

    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }
}


// ---------------------------------
//  .mv-with-cards-cards-part
// ---------------------------------
.mv-with-cards-cards-part {
  $_mv-upper-cards-height: auto;
  $_mv-upper-cards-md-height: 30rem;

  text-align: center;
  position: relative;
  margin: -22rem auto auto auto;
  width: 70%;
  height: $_mv-upper-cards-height;
  border: 1rem solid colors("white");
  background: colors("white");

  @include media-breakpoint-down(md) {
    margin: 0;
    width: 100%;
    border: 0;
  }

  &__item {
    position: relative;
    box-shadow: 2px 4px 8px colors("gray-800");

    &:hover {
      opacity: 0.8;
      transition: 0.8s;
    }

    @include media-breakpoint-down(md) {
      height: $_mv-upper-cards-md-height;
      display: flex;
    }
  }

  &__link {
    display: flex;
    width: 100%;
  }

  &__img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__txts {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    padding: 1rem 1.5rem;
    text-shadow: 2px 2px 6px colors("black");

    @include media-breakpoint-down(md) {
      padding: 1rem 3rem;
    }

    @include media-breakpoint-down(sm) {
      padding: 1rem 1.5rem;
    }

    &__ttl {
      font-size: 3rem;
    }

    &-lead {
      text-align: left;
      color: colors("white");
      font-weight: bold;
      font-size: 1.4rem;
      line-height: 1.8;
    }
  }
}
