// heading
.heading {
  $_font-size-sm-ratio: 0.7;

  margin: 0 0 1.5rem 0;
  font-weight: bold;

  // type_1
  &--type_1-1 {
    $_font-size: 3.8rem;
    $_font-sm-size: calc(#{$_font-size} * #{$_font-size-sm-ratio});

    color: $heading-color-1;
    font-size: $_font-size;

    @include media-breakpoint-down(sm) { font-size: $_font-sm-size; }
  }

  &--type_1-2 {
    $_font-size: 3rem;
    $_font-sm-size: calc(#{$_font-size} * #{$_font-size-sm-ratio});

    color: $heading-color-1;
    font-size: $_font-size;

    @include media-breakpoint-down(sm) { font-size: $_font-sm-size; }
  }

  &--type_1-3 {
    $_font-size: 2.4rem;
    $_font-sm-size: calc(#{$_font-size} * #{$_font-size-sm-ratio});

    color: $heading-color-1;
    font-size: $_font-size;

    @include media-breakpoint-down(sm) { font-size: $_font-sm-size; }
  }

  &--type_1-4 {
    $_font-size: 1.8rem;
    $_font-sm-size: calc(#{$_font-size} * #{$_font-size-sm-ratio});

    color: $heading-color-1;
    font-size: $_font-size;

    @include media-breakpoint-down(sm) { font-size: $_font-sm-size; }
  }

  // type_2
  &--type_2-1 {
    $_font-size: 3.8rem;
    $_font-sm-size: calc(#{$_font-size} * #{$_font-size-sm-ratio});

    color: $heading-color-2;
    font-size: $_font-size;

    @include media-breakpoint-down(sm) { font-size: $_font-sm-size; }
  }

  &--type_2-2 {
    $_font-size: 3rem;
    $_font-sm-size: calc(#{$_font-size} * #{$_font-size-sm-ratio});

    color: $heading-color-2;
    font-size: $_font-size;

    @include media-breakpoint-down(sm) { font-size: $_font-sm-size; }
  }

  &--type_2-3 {
    $_font-size: 2.2rem;
    $_font-sm-size: calc(#{$_font-size} * #{$_font-size-sm-ratio});

    color: $heading-color-2;
    font-size: $_font-size;

    @include media-breakpoint-down(sm) { font-size: $_font-sm-size; }
  }

  &--type_2-4 {
    $_font-size: 1.8rem;
    $_font-sm-size: calc(#{$_font-size} * #{$_font-size-sm-ratio});

    color: $heading-color-2;
    font-size: $_font-size;

    @include media-breakpoint-down(sm) { font-size: $_font-sm-size; }
  }

  // type_3
  &--type_3-1 {
    $_font-size: 3.2rem;
    $_font-sm-size: calc(#{$_font-size} * #{$_font-size-sm-ratio});

    color: $heading-color-3;
    font-size: $_font-size;

    @include media-breakpoint-down(sm) { font-size: $_font-sm-size; }
  }

  &--type_3-2 {
    $_font-size: 2.4rem;
    $_font-sm-size: calc(#{$_font-size} * #{$_font-size-sm-ratio});

    color: $heading-color-3;
    font-size: $_font-size;

    @include media-breakpoint-down(sm) { font-size: $_font-sm-size; }
  }

  &--type_3-3 {
    $_font-size: 2.4rem;
    $_font-sm-size: calc(#{$_font-size} * #{$_font-size-sm-ratio});

    color: $heading-color-3;
    font-size: $_font-size;

    @include media-breakpoint-down(sm) { font-size: $_font-sm-size; }
  }

  &--type_3-4 {
    $_font-size: 1.8rem;
    $_font-sm-size: calc(#{$_font-size} * #{$_font-size-sm-ratio});

    color: $heading-color-3;
    font-size: $_font-size;

    @include media-breakpoint-down(sm) { font-size: $_font-sm-size; }
  }
}

// lead
// .lead { }
