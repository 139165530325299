.btn {
  $_root: &;
  $_add-btn: $_root + "--type_add";
  $_added-btn: $_root + "--type_added";

  transition: all 0.3s ease;
  font-weight: bold;
  background-color: colors("blue");
  color: colors("white");
  border-radius: 3rem;
  border-width: 2px;
  line-height: 1.2;
  padding: 1.2rem 2rem 0.8rem 2rem;
  text-decoration: none;
  display: inline-block;

  // Type (Lv1 Modifier)
  &--type_cmn {
    $_bg-color: colors("blue");
    $_border-color: colors("blue");
    $_color: colors("white");
    $_bg-hvr-color: colors("white");
    $_border-hvr-color: colors("blue");
    $_hvr-color: colors("blue");

    background-color: $_bg-color;
    color: $_color;
    border-color: $_border-color;

    &:hover {
      background-color: $_bg-hvr-color;
      color: $_hvr-color;
      border-color: $_border-hvr-color;
    }
  }

  &--type_cmn-rvs {
    $_bg-color: colors("white");
    $_border-color: colors("blue");
    $_color: colors("blue");
    $_bg-hvr-color: colors("blue");
    $_border-hvr-color: colors("blue");
    $_hvr-color: colors("white");

    background-color: $_bg-color;
    color: $_color;
    border-color: $_border-color;

    &:hover {
      background-color: $_bg-hvr-color;
      color: $_hvr-color;
      border-color: $_border-hvr-color;
    }
  }

  &--type_cmn-red {
    $_bg-color: colors("red");
    $_border-color: colors("white");
    $_color: colors("white");
    $_bg-hvr-color: colors("white");
    $_border-hvr-color: colors("red");
    $_hvr-color: colors("red");

    background-color: $_bg-color;
    color: $_color;
    border-color: $_border-color;

    &:hover {
      background-color: $_bg-hvr-color;
      color: $_hvr-color;
      border-color: $_border-hvr-color;
    }
  }

  &--type_cmn-red-rvs {
    $_bg-color: colors("white");
    $_border-color: colors("red");
    $_color: colors("red");
    $_bg-hvr-color: colors("red");
    $_border-hvr-color: colors("red");
    $_hvr-color: colors("white");

    background-color: $_bg-color;
    color: $_color;
    border-color: $_border-color;

    &:hover {
      background-color: $_bg-hvr-color;
      color: $_hvr-color;
      border-color: $_border-hvr-color;
    }
  }

  &--type_cmn-white {
    $_bg-color: colors("white");
    $_border-color: colors("white");
    $_color: colors("blue");
    $_bg-hvr-color: colors("blue");
    $_border-hvr-color: colors("white");
    $_hvr-color: colors("white");

    background-color: $_bg-color;
    color: $_color;
    border-color: $_border-color;

    &:hover {
      background-color: $_bg-hvr-color;
      color: $_hvr-color;
      border-color: $_border-hvr-color;
    }
  }

  &--type_add {
    $_bg-color: colors("white");
    $_border-color: colors("red-600");
    $_color: colors("red-600");
    $_bg-hvr-color: colors("red");
    $_border-hvr-color: colors("red-600");
    $_hvr-color: colors("white");

    background-color: $_bg-color;
    color: $_color;
    border-color: $_border-color;

    &:hover {
      background-color: $_bg-hvr-color;
      color: $_hvr-color;
      border-color: $_border-hvr-color;
    }
  }

  &--type_added {
    $_bg-color: colors("blue");
    $_border-color: colors("red-600");
    $_color: colors("white");
    $_bg-hvr-color: colors("blue");
    $_border-hvr-color: colors("red-600");
    $_hvr-color: colors("white");

    background-color: $_bg-color;
    color: $_color;
    border-color: $_border-color;

    &:hover {
      background-color: $_bg-hvr-color;
      color: $_hvr-color;
      border-color: $_border-hvr-color;
    }
  }

  // Size
  &--size_lg {
    min-width: 320px;
  }

  // Inner Icon
  // --------------------
  &__icon {
    #{$_add-btn} & {
      color: colors("red");
    }

    #{$_add-btn}:hover & {
      color: colors("white");
    }

    #{$_added-btn} & {
      color: colors("red");
    }

    #{$_added-btn}:hover & {
      color: colors("red");
    }
  }
}
