// Sec-top-mv
// ------------------------------
// .Sec-top-mv {}

.top-mv {
  position: relative;
  width: 100%;

  // &__video {}

  // &__video-item {}

  &__uppers {
    position: absolute;
    text-align: center;
    width: 100%;
    padding: 0 3rem;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__txts {
    width: 100%;
  }

  &__logo {
    width: 180px;
    height: auto;

    @include media-breakpoint-down(sm) {
      width: 20vw;
      min-width: 100px;
    }
  }

  &__ttl {
    font-size: 1.4rem;
    color: colors("white");
    text-shadow: 2px 2px 6px colors("black");
  }

  &__searchbox {
    width: 50vw;
    margin: auto;
    text-align: center;
    height: auto;

    @include media-breakpoint-down(xs) {
      width: 80vw;
    }
  }

  // [note] google gcse wrapper tag can not add class.
  // so I added id. and I use it as style context.
  #top-mv__gcse {
    .gsc-control-cse {
      padding: 5px 5px 0 5px;
    }

    .gsc-results-wrapper-overlay {
      left: 0;
      right: 0;
      width: 80%;
    }

    // [note] the el create input gutter
    .gsib_a {
      @include media-breakpoint-down(md) {
        padding: 2px;
      }
    }

    td.gsc-search-button {
      padding: 0;
      margin: 0;
    }

    button.gsc-search-button {
      margin: 0;
      border: 4px solid #333; // [note] default button background color
    }

    .gsc-thumbnail-inside {
      text-align: left;
    }
  }
}


// Sec-top-about
// ------------------------------
// .Sec-top-about {}

.top-about {
  position: relative;
  display: flex;

  &__img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
  }
}

.top-about-txts {
  width: 100%;
  text-align: center;
  z-index: 1;
  padding: 4rem 10rem;

  @include media-breakpoint-down(md) {
    padding: 2rem 3rem;
  }

  &__ttl {
    margin-bottom: 0;
  }

  &__subttl {
    margin-bottom: 2rem;
  }

  &__lead {
    color: colors("white");
    line-height: 2.2;
    font-weight: bold;
    text-shadow: 2px 2px 4px colors("black");

    @include media-breakpoint-down(md) {
      text-align: left;
    }
  }

  &__btn {
    display: inline-block;
    width: 20rem;
    margin-top: 3rem;

    @include media-breakpoint-down(md) {
      margin-top: 1rem;
    }
  }
}

// Sec-top-reasons
// ------------------------------
// .Sec-top-reasons {}

.top-reasons {
  text-align: center;
}

.top-reasons-ttls {
  padding: 3rem 0;

  &__ttl {
    vertical-align: text-top;
    display: inline-block;
    margin-bottom: 0;
  }
}

.top-reasons-content {
  position: relative;
  padding: 0;

  &:hover {
    opacity: 0.8;
    transition: 0.8s;
  }

  &__link {
    display: flex;
  }

  &__img {
    object-fit: cover;
    width: 100%;

    @include media-breakpoint-down(md) {
      height: 30rem;
    }
  }
}

.top-reasons-content-txts {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  width: 100%;
  font-weight: bold;
  text-shadow: 2px 2px 6px colors("black");
  padding: 0 3rem;

  @include media-breakpoint-down(md) {
    text-align: left;
  }

  &__ttl {
    margin-bottom: 0;
  }

  &__subttl {
    color: colors("white");
    margin-bottom: 2rem;
  }

  &__lead {
    color: colors("white");
  }
}

// Sec-top-howto
// ------------------------------
// .Sec-top-howto {}

.top-howto {
  background-color: colors("blue");
}

.top-howto-imgs {
  padding: 0;

  &__img {
    width: 100%;
  }
}

.top-howto-txt {
  margin: auto 0;
  padding: 8rem;
  text-align: center;

  @include media-breakpoint-down(md) {
    padding: 4rem 3rem;
  }

  &__ttl {
    margin-bottom: 3rem;
  }

  &__lead {
    color: colors("white");
    margin-bottom: 3rem;
    font-weight: bold;
    text-align: left;
    line-height: 1.8;
  }

  &__btn {
    width: 20rem;
  }
}

// Sec-top-recommend
// ------------------------------
// .Sec-top-recommend {}

.top-recommend-txts {
  text-align: center;
  margin-bottom: 2rem;

  &__ttl {
    margin-bottom: 0;
  }

  &__subttl {
    margin-bottom: 0;
  }
}

// Sec-top-movie
// ------------------------------
// .Sec-top-movie {}

.top-movie-txts {
  text-align: center;
  margin-bottom: 2rem;

  &__ttl {
    margin-bottom: 0;
  }

  &__lead {
    margin-bottom: 0;
  }
}

.top-movie-videos {
  background-color: colors("black");
  padding: 3rem 0;

  @include media-breakpoint-down(sm) {
    padding: 0;
  }

  &__video {
    position: relative;
    padding-bottom: 56.25%; // アスペクト比 16:9の場合の縦幅
    height: 0;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.top-movie-spots {
  margin-top: 4rem;
}

.top-movie-spots-txts {
  text-align: center;
  margin-bottom: 2rem;

  &__ttl {
    margin-bottom: 0;
  }
}

// Sec-top-articles
// ------------------------------
.Sec-top-articles {
  background-color: colors("blue-200");
}

.top-articles {
  padding: 4rem 1.5rem;
}

.top-articles-txts {
  text-align: center;

  &__ttl {
    margin-bottom: 0;
  }

  &__subttl {
    margin-bottom: 0;
  }
}

.top-articles-column {
  background-color: colors("white");
  margin: 2rem 0;
  padding: 2rem;

  &__ttl {
    margin-top: 1rem;
  }

  &__link {
    text-decoration: none;
  }

  &__lead {
    margin-bottom: 0;
  }
}

.top-articles-btns {
  text-align: center;
  margin-top: 4rem;

  &__btn {
    display: inline-block;
    width: 20rem;
  }
}

// Sec-top-bottom-cnt
// ------------------------------
// .Sec-top-bottom-cnt {}

.top-instagram {
  text-align: center;
  margin: 2rem 0;
}

.top-instagram-txts {
  text-align: center;

  &__ttl {
    margin-bottom: 0;
  }
}

.top-instagram-btns {
  text-align: center;
  margin-top: 4rem;

  &__btn {
    display: inline-block;
    width: 20rem;
  }
}

.top-facebook {
  text-align: center;
  margin: 2rem 0;
}

.top-pr {
  text-align: center;
  margin: 2rem 0;
}

.top-pr-lists {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.top-pr-list {
  vertical-align: middle;
  margin: 0.5rem 0;

  &__link {
    display: block;

    &:hover {
      opacity: 0.8;
      transition: 0.8s;
    }
  }

  &__img {
    width: 100%;
  }
}
