// ---------------------------------
// .site-bottom-info-bar
// ---------------------------------

.site-bottom-info-bar {
  background-color: colors("black");
  bottom: 0;
  position: fixed;
  z-index: 998;
  width: 100%;
  padding: 2rem 1rem;

  &__lead {
    color: colors("white");
    margin: 0 10rem 0 0;
  }

  &__btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    margin-right: 1rem;
  }
}
