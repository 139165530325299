// Sec-itineraries-mv
// ------------------------------
//.Sec-itineraries-mv {}

.itineraries-mv {
  position: relative;

  &__img {
    height: auto;
    width: 100%;
    object-fit: cover;

    @include media-breakpoint-down(md) {
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
}

.itineraries-mv-txts {
  position: absolute;
  text-align: center;
  width: 100%;
  padding: 0 3rem;
  text-shadow: 2px 2px 6px colors("black");
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);

  @include media-breakpoint-down(md) {
    position: static;
    padding: 2rem 1.5rem;
    z-index: 1;
    top: 0;
    transform: translateY(0);
  }

  &__lead {
    color: colors("white");
    margin-bottom: 0;
    font-weight: bold;
    line-height: 1.8;
    text-shadow: 2px 2px 6px colors("black");

    @include media-breakpoint-down(md) {
      font-size: 1.4rem;
    }
  }
}

// Sec-itineraries-article
// ------------------------------
//.Sec-itineraries-article {}

.itineraries-article {
  margin: 2.5rem 0;

  // &__img-wrap {}

  &__img {
    height: auto;
    width: 100%;
  }

  // &__txts {}

  // &__btn {}
}


// Sec-itineraries-show-mv
// ------------------------------
//.Sec-itineraries-show-mv {}

.itineraries-show-mv {
  margin-top: 2.5rem;
}

.itineraries-show-mv-imgs {
  &__img {
    height: auto;
    width: 100%;
    padding: 0 0 20px;
  }
}

// Sec-itineraries-show-overview
// ------------------------------
//.Sec-itineraries-show-overview {}

.itineraries-show-overviews-ttls {
  text-align: center;
}

.itineraries-show-overview {
  border-style: solid;
  border-color: colors("black-100");

  &__btn {
    width: 80%;

    @include media-breakpoint-down(xs) {
      width: 60%;
    }
  }
}

.itineraries-show-overview-bottom {
  text-align: center;
  margin-top: 4rem;

  &__btn {
    display: inline-block;
    width: 30%;

    @include media-breakpoint-down(sm) {
      width: 70%;
    }
  }
}

// Sec-itineraries-course
// ------------------------------
//.Sec-itineraries-course {}

// .itineraries-courses {}

.itineraries-course-start {
  &__ttl {
    text-align: center;
  }
}

.itineraries-course-start-firstline {
  &__times {
    text-align: center;
    background-color: colors("blue-100");
    color: colors("blue");
    margin-right: 5px;
  }

  &__time {
    margin-bottom: 0;
    font-weight: bold;
  }

  &__details {
    display: flex;
    align-items: center;
    margin-right: -5px;
    border-style: solid;
    border-color: #ebf3f9;
  }
}

.itineraries-course-start-secondline {
  &__bars {
    text-align: center;
    height: 100px;
  }

  &__bar {
    background-color: colors("blue-100");
    height: 100%;
    width: 20%;
    display: inline-block;
  }

  &__times {
    display: flex;
    align-items: center;
  }

  &__time {
    color: colors("blue");
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0;
  }
}

// .itineraries-course {}

.itineraries-course-firstline {
  &__times {
    text-align: center;
    background-color: colors("blue-100");
    color: colors("blue");
    margin-right: 5px;

    @include media-breakpoint-down(md) {
      margin-right: 0;
      margin-bottom: 5px;
    }
  }

  &__time {
    margin-bottom: 0;
    font-weight: bold;
  }

  &__details {
    margin-right: -5px;
    border-style: solid;
    border-color: #ebf3f9;

    @include media-breakpoint-down(md) {
      margin-right: 0;
    }

    &-ttls {
      display: flex;
      align-items: center;
    }

    &-num {
      background-color: colors("blue");
      color: colors("white");
      padding: 10px 15px;
      font-weight: bold;
    }

    &-ttl {
      margin-bottom: 0;
      padding-left: 10px;
    }

    &-img {
      width: 100%;
      padding: 10px 0 20px;
    }

    // &-lead {}

    &-btn {
      width: 30%;

      @include media-breakpoint-down(md) {
        width: 60%;
        display: block;
        margin-right: auto;
        margin-left: auto;
      }
    }
  }
}

.itineraries-course-secondline {
  &__bars {
    text-align: center;
    height: 100px;
  }

  &__bar {
    background-color: colors("blue-100");
    height: 100%;
    width: 20%;
    display: inline-block;
  }

  &__times {
    display: flex;
    align-items: center;
  }

  &__time {
    color: colors("blue");
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0;
  }
}

// Sec-itineraries-show-others
// ------------------------------
//.Sec-itineraries-show-others {}

.itineraries-show-others-ttls {
  text-align: center;
}
