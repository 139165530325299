// object-fit ( only IE11 polyfill )
// ------------------------------
@mixin object-fit-polyfill-for-ie11($fit: cover, $position: center) {
  font-family: "object-fit: #{$fit}; object-position: #{$position};";
}

// [note] Class name for starting JavaScript
// .enable-ofi-polyfill {}

// [note] Utility classes for IE11
.ofi-cover-center { @include object-fit-polyfill-for-ie11($fit: cover, $position: center); }
.ofi-contain-center { @include object-fit-polyfill-for-ie11($fit: contain, $position: center); }
.ofi-cover-top { @include object-fit-polyfill-for-ie11($fit: cover, $position: top); }
.ofi-contain-top { @include object-fit-polyfill-for-ie11($fit: contain, $position: top); }
.ofi-cover-bottom { @include object-fit-polyfill-for-ie11($fit: cover, $position: bottom); }
.ofi-contain-bottom { @include object-fit-polyfill-for-ie11($fit: contain, $position: bottom); }
