// ========================================
// footer
// ========================================

// ---------------------------------
// Main container
// ---------------------------------
.site-footer {
  background-color: #050322;
  color: colors("white");

  &__logo {
    text-align: center;
    margin: 2rem 0;

    &-img {
      height: 100px;
      width: auto;
    }
  }

  &__items {
    width: 100%;
    text-align: center;
    margin-bottom: 4rem;
  }

  &__item {
    list-style: none;
    display: inline-block;
    margin: 0 1rem;

    @include media-breakpoint-down(sm) {
      margin: 1rem;
    }

    &-link {
      text-decoration: none;
      color: colors("white");
      display: flex;
      justify-content: center;
      transition: color 0.3s ease;
      font-weight: bold;
      font-size: 1.4rem;

      &:hover {
        color: colors("gray-200");
        text-decoration: none;
      }
    }
  }

  &__copyright {
    width: 100%;
    font-size: 1.4rem;
    margin: 2rem 0;
  }
}

// ---------------------------------
// Inner parts
// ---------------------------------
.site-footer-logo {
  padding-top: 14px;
  font-size: 1.4rem;
  font-weight: bold;
}
