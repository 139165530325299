// ---------------------------------
// breadcrumb
// ---------------------------------
// .breadcrumb {}

.site-breadcrumb {
  background-color: colors("white");
}

.site-breadcrumb-items {
  text-align: center;
  list-style: none;
  font-weight: bold;
  padding: 2rem 0 1.5rem;
  margin: 0;

  &__item {
    display: inline-block;
  }

  &__item-icon {
    margin: 0 1rem;
  }

  &__item-link {
    text-decoration: none;
  }
}
