// Sec-spots-mv
// ------------------------------
//.Sec-spots-mv {}

.spots-mv {
  position: relative;

  &__img {
    height: auto;
    width: 100%;
    object-fit: cover;

    @include media-breakpoint-down(md) {
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
}

.spots-mv-txts {
  position: absolute;
  text-align: center;
  width: 100%;
  padding: 0 3rem;
  text-shadow: 2px 2px 6px colors("black");
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);

  @include media-breakpoint-down(md) {
    position: static;
    padding: 2rem 1.5rem;
    z-index: 1;
    top: 0;
    transform: translateY(0);
  }

  &__lead {
    color: colors("white");
    margin-bottom: 0;
    font-weight: bold;
    line-height: 1.8;
    text-shadow: 2px 2px 6px colors("black");

    @include media-breakpoint-down(md) {
      font-size: 1.4rem;
    }
  }
}

// Sec-spots-searchbox
// ------------------------------
//.Sec-spots-searchbox {}

.spots-searchbox {
  background-color: colors("blue-100");
  background-clip: content-box;
}

.spots-searchbox-ttls {
  background-color: colors("blue-500");

  &__ttl {
    text-align: center;
    padding: 2rem 0 1rem;
    margin-bottom: 0;
  }
}

.spots-searchbox-checkarea {
  display: block;
  margin: 2rem 0;
}

.spots-searchbox-checkarea-ttls {
  background-color: colors("white");
  border-style: solid;
  border-width: thin;
  display: flex;
  align-items: center;
  padding: 0;
  text-align: center;

  @include media-breakpoint-up(lg) {
    border-right: none;
  }

  &__ttl {
    width: 100%;
    margin-bottom: 0;
  }
}

.spots-searchbox-checkarea-checks {
  background-color: colors("white");
  border-style: solid;
  border-width: thin;
  padding: 0.5rem 1.5rem;

  @include media-breakpoint-down(md) {
    max-width: 72%;
    margin-left: 3%;
  }
}

.spots-searchbox-checkarea-checks-items {
  letter-spacing: -0.4rem;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.spots-searchbox-checkarea-checks-item {
  letter-spacing: normal;
  font-weight: bold;
  padding: 0.5rem 1rem;
  display: inline-block;

  @include media-breakpoint-down(sm) {
    padding: 0.5rem;
  }

  &__checkbox {
    float: left;
    margin-top: 0.2rem;
    cursor: pointer;
  }

  &__txt {
    padding-left: 0.5rem;
    margin-bottom: 0;
    cursor: pointer;
  }
}

.spots-searchbox-inputarea {
  margin: 2rem 0;

  &__ttl {
    text-align: center;
    margin-bottom: 0;
  }

  &__txt {
    width: 100%;
    padding: 1rem 1rem 0.5rem;
    font-weight: bold;
  }

  &__subtxt {
    margin: 1rem 0 0 1rem;

    @include media-breakpoint-down(xs) {
      margin: 1rem 0 0 0;
    }
  }
}

.spots-searchbox-btns {
  text-align: center;

  &__btn {
    width: 300px;

    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }
}

// Sec-spots-result
// ------------------------------
//.Sec-spots-result {}

.spots-results-num {
  &__txt {
    font-weight: bold;
  }
}

.spots-result {
  margin: 15px 0;
}

.spots-result-btns {
  &__btn {
    width: 180px;

    @include media-breakpoint-down(md) {
      display: block;
      width: auto;
      padding: 1rem 0 0.6rem;
    }
  }
}

.spots-result-txts {
  &__link {
    text-decoration: none;
  }
}

// spots-results-paginations
// ------------------------------
.spots-results-pagenations {
  margin-top: 3rem;

  .nav-links {
    display: flex;
  }

  .pagination {
    justify-content: center;
  }

  .screen-reader-text {
    display: none;
  }

  .page-numbers {
    background-color: color("black-400");
    padding: 0.6rem 1rem 0.2rem 1rem;
    margin: 0 0.5rem;
    font-size: 2rem;
    color: colors("white");
    text-decoration: none;
    font-weight: bold;
    transition: 0.6s;
    cursor: pointer;

    &:hover {
      background-color: color("black-600");
    }

    &.dots {
      background-color: color("white");
      color: color("black-400");
      cursor: default;
      height: 1rem;
      padding: 1.3rem 0 0 0 !important;

      &:hover {
        background-color: color("white");
      }
    }

    @include media-breakpoint-down(xs) {
      padding: 0.4rem 0.6rem 0;

      &.page-numbers {
        padding: 0.6rem 1rem 0;
        margin: 0 0.2rem;
      }

      &.prev {
        font-size: 1.8rem;
        padding: 0.6rem 0 0;
        margin: 0 0.5rem 0 0;
      }

      &.next {
        font-size: 1.8rem;
        padding: 0.6rem 0 0;
        margin: 0 0 0 0.5rem;
      }
    }
  }

  .current {
    background-color: color("blue");
  }

  .next {
    background-color: color("white");
    color: color("blue");

    &:hover {
      background-color: color("blue-800");
      color: color("white");
    }
  }

  .prev {
    background-color: color("white");
    color: color("blue");

    &:hover {
      background-color: color("blue-800");
      color: color("white");
    }
  }
}

// Sec-spots-show-mv
// ------------------------------
//.Sec-spots-show-mv {}

.spots-show-mv {
  margin-top: 2.5rem;
}

.spots-show-mv-imgs {
  &__img {
    object-fit: contain;
    height: 100%;
    width: 100%;
    padding: 2rem 0;
  }
}

// Sec-spots-show-detail
// ------------------------------
//.Sec-spots-show-detail {}

.spots-show-detail {
  text-align: center;

  &__lead {
    text-align: left;
  }

  &__btn {
    width: 20rem;
    margin-top: 1rem;
  }
}

// Sec-spots-show-information
// ------------------------------
//.Sec-spots-show-information {}

.spots-show-information-ttls {
  &__ttl {
    text-align: center;
  }
}

.spots-show-information-items {
  border: solid 1px colors("blue-400");
}

.spots-show-information-item-ttls {
  border: solid 1px colors("blue-400");
  background-color: colors("blue-100");
  display: flex;
  align-items: center;

  @include media-breakpoint-down(xs) {
    padding: 0 0.5rem;
  }

  &__ttl {
    padding-top: 0.5rem;
    margin-bottom: 0;
  }
}

.spots-show-information-item-leads {
  border: solid 1px colors("blue-400");
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  @include media-breakpoint-down(xs) {
    padding: 0.5rem 1rem;
  }

  &__lead {
    padding-top: 0.5rem;
    margin-bottom: 0;
  }
}

.spots-show-information-btns {
  text-align: center;
  width: 100%;
  margin-top: 4rem;

  &__btn {
    width: 20rem;
  }
}

// Sec-spots-show-map
// ------------------------------
//.Sec-spots-show-map {}

.spots-show-map-ttls {
  &__ttl {
    text-align: center;
  }
}

.spots-show-map-items {
  &__item {
    width: 100%;
  }
}

// Sec-spots-show-spot
// ------------------------------
//.Sec-spots-show-spot {}

.spots-show-spots {
  &__ttl {
    text-align: center;
  }
}
