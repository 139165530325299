// ---------------------------------
// .stack-banners-for-related-pages
// ---------------------------------

.stack-banners-for-related-pages {
  &__item {
    padding: 1rem 0;
    position: relative;

    @include media-breakpoint-down(xs) {
      padding: 0.5rem 0;
    }
  }

  &__item-link {
    display: flex;
  }

  &__item-img {
    height: 15rem;
    width: 100%;
    object-fit: cover;

    &:hover {
      opacity: 0.8;
      transition: 0.8s;
    }
  }

  &__item-ttl {
    position: absolute;
    padding-top: 1rem;
    margin-bottom: 0;
    text-shadow: 2px 2px 8px colors("black");
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}
