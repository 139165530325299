// Sec-error404-mv
// ------------------------------
//.Sec-error404-mv {}

.error404-mv {
  position: relative;
  display: flex;
  align-items: center;
}

.error404-mv-imgs {
  height: auto;
  width: 100%;

  &__img {
    height: auto;
    width: 100%;
    object-fit: cover;

    @include media-breakpoint-down(md) {
      height: 44vw;
      width: auto;
    }
  }
}

.error404-mv-txts {
  position: absolute;
  width: 100%;
  text-align: center;
  padding: 0 3rem;

  @include media-breakpoint-down(md) {
    padding: 0 1.5rem;
  }

  &__lead {
    color: colors("white");
    margin-bottom: 0;
  }
}
