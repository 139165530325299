.cmn-carousel {
  $_carousel-pic-height: 20vw;
  $_carousel-pic-md-height: 260px;
  $_carousel-pic-sm-height: 300px;
  $_carousel-pager-y-padding: 20px;
  $_carousel-pager-icon-heihgt: 3rem;
  // $_carousel-pager-heihgt: calc(#{$_carousel-pager-y-padding} * 2 + #{$_carousel-pager-icon-heihgt});
  // $_carousel-pager-y-loc: calc(#{$_carousel-pic-height} / 2 - #{$_carousel-pager-heihgt} / 2);
  // $_carousel-pager-y-md-loc: calc(#{$_carousel-pic-md-height} / 2 - #{$_carousel-pager-heihgt} / 2);
  // $_carousel-pager-y-sm-loc: calc(#{$_carousel-pic-sm-height} / 2 - #{$_carousel-pager-heihgt} / 2);

  position: relative;

  // &--type_about {}

  // &__container {}

  // &__wrapper {}

  // &__slide {}

  &__slide-link {
    overflow: hidden;
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    border: solid 1px colors("gray-200");
  }

  &__slide-img {
    width: 100%;
    height: $_carousel-pic-height;
    object-fit: cover;
    transform: rotate(15deg) scale(1.4);
    -webkit-transition: 0.3s ease-in-out;

    @include media-breakpoint-down(md) {
      height: $_carousel-pic-md-height;
    }

    @include media-breakpoint-down(sm) {
      height: $_carousel-pic-sm-height;
    }

    &:hover {
      -webkit-transform: rotate(0) scale(1);
      transform: rotate(0) scale(1);
    }
  }

  // &__slide-btn {}

  &__slide-number {
    background-color: colors("blue");
    color: colors("white");
    padding: 10px 15px;
    margin-right: 10px;
  }

  &__slide-ttl {
    margin-bottom: 4rem;
  }

  &__slide-ttl-link {
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      text-decoration: none;
    }
  }

  // &__slide-description {}

  &__pager {
    position: absolute;
    padding: $_carousel-pager-y-padding 10px;
    background-color: rgba(colors("blue"), 0.8);
    cursor: pointer;
    z-index: 9;
    transition: background-color 0.3s ease;
    top: 25%;

    @include media-breakpoint-down(md) {
      top: 21%;
    }

    @include media-breakpoint-down(sm) {
      top: 25%;
    }

    &:hover {
      background-color: colors("blue");
    }

    &--type_prev {
      left: -10px;
    }

    &--type_next {
      right: -10px;
    }
  }

  &__pager-icon {
    font-size: $_carousel-pager-icon-heihgt;
    line-height: 1;
    color: colors("white");
  }
}
