// Sec-reasons-mv
// ------------------------------
//.Sec-reasons-mv {}
// [note]
// Use mv-with-cards component stylesheet
// If you want to add or override the component,
// You can use the page private class ↓
// .reasons-mv-with-cards {}


// Sec-reasons-article
// ------------------------------
// .Sec-reasons-article {

.reasons-articles {
  &__ttl {
    text-align: center;
  }
}

.reasons-article-imgs {
  &__img {
    height: auto;
    width: 100%;
  }
}

.reasons-article-txts {
  margin: 2rem;

  &__lead {
    text-align: left;
    color: colors("blue");
  }
}

.reasons-article-spots {
  margin: 2rem 0;

  &__ttl {
    text-align: center;
  }
}

// Sec-reasons-itineraries
// ------------------------------
//.Sec-reasons-itineraries {}

.reasons-itineraries-top {
  position: relative;
}

.reasons-itineraries-top-imgs {
  &__img {
    height: 20vw;
    width: 100%;
    object-fit: cover;

    @include media-breakpoint-down(md) {
      height: 33vw;
    }
  }
}

.reasons-itineraries-top-ttls {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);

  &__ttl {
    margin-bottom: 0;
  }
}

.reasons-itineraries-courses {
  margin: 2rem 0;
}

.reasons-itineraries-courses-btns {
  text-align: center;
  margin-top: 2rem;

  &__btn {
    display: inline-block;
  }
}
