// ========================================
// header
// ========================================

// ---------------------------------
// Main container
// [note] See globale values in _variables.scss
// ---------------------------------
$_logo-size: 100px;
$_logo-sm-size: $header-height;
$_sp-header-navi-bg-color: rgba(#060224, 0.82);

.site-header {
  height: $header-height;
  width: 100%;
  background-color: $header-bg-color;
  color: colors("white");
  position: fixed;
  top: 0;
  z-index: $zindex-header;
  box-shadow: 0 0 1px colors("alpha-black-80");
}


// ---------------------------------
// Inner parts
// ---------------------------------
.site-header-logo {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  background-color: $header-bg-color;
  z-index: 3;
  align-items: center;
  width: $_logo-size;
  height: $_logo-size;
  margin: 0;

  @include media-breakpoint-down(md) {
    display: inline-block;
    position: static;
    margin-right: 10px;
    width: $_logo-sm-size;
    height: $_logo-sm-size;
  }

  &__link {
    display: block;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &__img {
    width: $_logo-size;
    height: auto;

    @include media-breakpoint-down(sm) {
      width: $_logo-sm-size;
      height: $_logo-sm-size;
    }
  }

  &__txt {
    display: block;
    color: colors("white");
    width: auto;
    font-size: 1.2rem;
  }
}

.site-header-focus-nav {
  position: relative;
  z-index: 2;
  padding-left: $_logo-size;
  display: inline-block;

  &__items {
    display: flex;
  }

  &__item {
    margin-right: 2px;
    width: 80px;
    height: $header-height;
  }

  &__item-link {
    display: block;
    padding-top: 2px;
    height: $header-height;
    letter-spacing: normal;
    text-decoration: none;
    text-align: center;
    background-color: colors("gray-100");

    &:hover {
      text-decoration: none;
    }
  }

  &__item-img {
    width: auto;
    height: 60px;

    &:hover {
      opacity: 0.6;
      transition: 0.4s;
    }
  }
}

.site-header-for-sp-layout-wrap { // [note] wrapper of site-header-g-nav & site-header-sns-menu
  display: inline-block;
  vertical-align: top;

  @include media-breakpoint-down(md) {
    $_sp-wrap-x-padding-in-md: $_logo-size;
    $_sp-wrap-x-offset-in-md: 20px;

    display: block;
    position: absolute;
    overflow-y: auto;
    top: $header-height;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $_sp-header-navi-bg-color;
    padding: 30px $_sp-wrap-x-padding-in-md + $_sp-wrap-x-offset-in-md;
  }

  @include media-breakpoint-down(sm) {
    padding: 30px 20px;
  }
}

.site-header-g-nav {
  width: auto;
  padding-top: 10px;

  @include media-breakpoint-down(md) {
    padding: 0;
  }

  &__lists {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0 1rem;
    line-height: 1.2;
    font-size: 1.4rem;
    font-weight: bold;
    height: 50px;

    @include media-breakpoint-down(md) {
      display: block;
      font-size: 2.8rem;
      margin: 0 0 20px 0;
      width: 100%;
      height: auto;
    }
  }

  &__link {
    text-decoration: none;
    text-align: center;
    display: block;
    color: colors("white");
    width: 100%;
    transition: color 0.3s ease;

    &:hover {
      color: colors("gray-200");
      text-decoration: none;
    }

    @include media-breakpoint-down(md) {
      text-align: left;
    }
  }
}

.site-header-sns-menu {
  position: absolute;
  right: 10px;
  top: 22px;

  @include media-breakpoint-down(md) {
    position: static;
    padding-top: 10px;
    padding-bottom: 8px;
    border-top: 1px dotted rgba(colors("white"), 0.5);
    width: 100%;
  }

  &__link {
    color: colors("white");
    transition: color 0.3s ease;
    margin-right: 4px;

    &:hover {
      color: colors("gray-200");
    }

    @include media-breakpoint-down(md) {
      margin-right: 8px;
    }
  }

  &__icon {
    font-size: 2.4rem;

    @include media-breakpoint-down(md) {
      font-size: 3.2rem;
    }
  }
}

.site-header-lang-menus {
  @include media-breakpoint-down(md) {
    border-top: 1px dotted rgba(colors("white"), 0.5);
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.site-header-lang-menu {
  position: absolute;
  right: 10px;
  top: 22px;

  @include media-breakpoint-down(md) {
    position: static;
    padding-top: 10px;
    border-top: 1px dotted rgba(colors("white"), 0.5);
    width: 100%;
  }

  &__drop {
    margin: 2rem 0.5rem;

    &--btn {
      color: $white;
      border: none;
      background-color: #050322;
      font-weight: bold;
      font-size: 1.6rem;

      @include media-breakpoint-down(md) {
        font-size: 2.8rem;
        background-color: rgba(6, 2, 36, 0.82);
      }

      &:focus {
        outline: none;
      }
    }
  }

  &__list {
    margin: 1rem;
  }

  &__link {
    color: #050322;
    text-decoration: none;
    font-weight: bold;

    &:hover {
      color: #050322;
    }

    &--state_current {
      color: colors('blue');
      cursor: not-allowed;

      &:hover {
        color: colors('blue');
        text-decoration: none;
      }
    }
  }
}

.site-header-lang-menu-sp {
  &__title {
    font-size: 2.4rem;
    font-weight: bold;
  }

  &__lists {
    padding: 0;
    margin: 0;
  }

  &__list {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 0 8px 0 0;
    font-weight: bold;
  }

  &__link {
    color: $white;
    font-size: 2rem;

    &:hover {
      color: rgba(255, 255, 255, 0.8);
    }

    &--state_current {
      color: colors('blue');
      cursor: not-allowed;

      &:hover { color: colors('blue'); }
    }
  }
}

.site-header-jtp-menu {
  display: inline-block;
  position: relative;
  z-index: 2;
  vertical-align: top;
  margin-top: 25px;

  @include media-breakpoint-down(sm) {
    padding-left: 0; // [note] Because logo no has position properties.
  }

  // &__items {}

  &__link {
    margin-right: 5px;
    color: colors("white");
    transition: color 0.3s ease;
    text-decoration: none;

    &:hover {
      color: colors("gray-200");
      text-decoration: none;
    }

    &--type_fav {
      color: colors("red");

      &:hover {
        color: darken(colors("red"), 10%);
      }
    }
  }
}

.site-header-menu-toggler {
  $_root: &;
  $_size: $header-height;
  $_active: ".target-active";
  // colors
  $_color: colors("white");
  $_hvr-color: rgba($_color, 0.8);
  $_bg-color: inherit;
  $_bg-hvr-color: inherit;
  $_active-color: $header-bg-color;
  $_active-hvr-color: colors("red");
  $_active-bg-color: colors("white");
  $_active-bg-hvr-color: rgba($_active-bg-color, 0.8);

  position: absolute;
  top: 0;
  right: 0;
  width: $_size;
  height: $_size;
  color: $_color;
  background-color: $_bg-color;
  font-size: 4rem;
  text-align: center;
  vertical-align: middle;
  z-index: 3;
  transition: color 0.3s ease, background-color 0.3s ease;
  cursor: pointer;

  &:hover {
    color: $_hvr-color;
    background-color: $_bg-hvr-color;
  }

  &#{$_active} {
    background-color: $_active-bg-color;
    color: $_active-color;

    &:hover {
      color: $_active-hvr-color;
      background-color: $_active-bg-hvr-color;
    }
  }

  &__icon {
    line-height: 1.6;// [note] I use the param for vertical align withvertical-align
    vertical-align: middle;
  }
}
