// Sec-about-mv
// ------------------------------
//.Sec-about-mv {}

// Sec-about-article
// ------------------------------
//.Sec-about-article {}

.about-article {
  // Parent Wrapper Elm
  $_area-height: 460px;
  $_area-md-height: auto;
  $_area-inner-y-offset: 16px;
  // Blue text Box Elm
  $_txts-max-height: $_area-height - ($_area-inner-y-offset * 2);
  $_txts-l-offset: 82px; // [note] Sorry, hardcording.
  $_txts-inner-offset: 32px;
  // Back Image Elm
  $_mv-height: 100%;
  $_mv-md-height: 320px;

  position: relative;
  height: $_area-height;
  width: 100%;

  @include media-breakpoint-down(md) {
    height: $_area-md-height;
  }

  &__txts {
    @include centering($type: transform-y);

    margin-left: $_txts-l-offset;
    padding: $_txts-inner-offset;
    width: 45%;
    max-width: 640px;
    height: auto;
    min-height: 300px;
    max-height: $_txts-max-height;
    background-color: colors("blue");
    box-shadow: 2px 2px 14px colors("black");
    text-shadow: 2px 2px 6px colors("black-900");
    opacity: 0.9;
    z-index: 2;

    @include media-breakpoint-down(md) {
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      transform: translateY(0%);
      margin: 0;
      width: 100%;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      box-shadow: none;
    }
  }

  &__txt {
    overflow-y: auto;
    max-height: $_txts-max-height - ($_txts-inner-offset * 2);

    @include media-breakpoint-down(md) {
      overflow-y: inherit;
      height: auto;
      max-height: 100%;
    }
  }

  &__txt-ttl {
    text-align: left;
  }

  &__txt-lead {
    color: colors("white");
    text-align: left;
    line-height: 1.8;
  }


  &__mv {
    @include centering();

    height: $_mv-height;
    width: 100%;
    z-index: 1;

    @include media-breakpoint-down(md) {
      position: static; // IE11対応のため
      display: block;
      height: $_mv-md-height;
      transform: translateY(0%) translateX(0%); // Reset centering()
    }
  }

  &__mv-img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: $_mv-height;
    width: 100%;
    object-fit: cover;

    @include media-breakpoint-down(md) {
      height: $_mv-md-height;
    }
  }
}

.about-article-sekicitynow {
  background-color: colors("white");
  display: flex;
  align-items: center;
  height: 44vw;

  @include media-breakpoint-down(md) {
    height: auto; // IE11対応のため
  }

  // &__img {}
}

.about-article-sekicitynow-txts {
  align-items: center;
  display: flex;

  &__txt {
    margin-right: auto;

    &-ttl {
      width: 80%;
      text-align: left;
      display: inline-block;
    }

    &-lead {
      color: colors("blue");
      width: 80%;
      text-align: left;
      display: inline-block;
      line-height: 1.8;
    }
  }
}


// Sec-about-map
// ------------------------------
//.Sec-about-map {}

.about-map-ttls {
  text-align: center;
}

.about-map-imgs {
  &__img {
    width: 100%;
  }
}
